.lang-switcher {
  position: absolute;
  display: inline-block;
  top: 160px;
  right: 0;
  z-index: 1;
  @include mq($until: 1880px){
    top: 15px;
    visibility: visible!important;
    z-index: 4;
  }
  .selected {
    cursor: pointer;
    transition: ease 0.3s all;
    display: flex;
    align-items: center;
    padding: 20px 20px 20px 0;
    position: relative;
    background: $color-extra;
    font-weight: 700;
    font-size: 24px;
    @include mq($until: 1880px){
      padding: 10px 20px 10px 0;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    &:after {
      content: '';
      position: absolute;
      left: -30px;
      top: 0;
      width: 100%;
      height: 100%;
      background: $color-extra;
      transform: skew(-30deg, 0deg);
      transition: ease 0.3s all;
      z-index: -1;
    }  
    span {
      color: #fff;
      font-weight: 700;
      font-size: 24px;
    }
    svg {
      transition: ease 0.3s all;
      margin-left: 10px;
      @include mq($until: sm){
        display: none;
      }
    }
    @include mq($until: sm){
      span {
        display: none;
      }
    }
    img {
      margin-right: 10px;
      margin-left: -42px;
      @include mq($until: md){
        width: 34px;
        height: auto;
      }
      @include mq($until: sm){
        margin-left: 0;
      }
    }
    &:hover, &:hover:after {
      background-color: $color-main;
    }
  }
  .list {
    display: none;
    position: absolute;
    z-index: 9;
    top: 100%;
    margin: 0 0 0 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    li {
      list-style: none;
      padding: 0;
      margin: 0;
      position: relative;
      &:last-of-type {
        border-bottom: none;
      }
      &.current {
        background-color: rgba(75, 35, 206, 0.05);
      }
      &:before {
        display: none;
      }
    }
    a {
      padding: 20px 20px 20px 0;
      display: flex;
      align-items: center;
      font-weight: 700;
      text-decoration: none;
      transition: ease 0.3s background-color;
      span {
        color: #06122A;
        @include mq($until: sm){
          display: none;
        }
      }
      &:after {
        content: '';
        position: absolute;
        left: -30px;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        transform: skew(-30deg, 0deg);
        transition: ease 0.3s all;
        z-index: -1;
      }    
    }
    img {
      margin-right: 10px;
      @include mq($until: sm){
        margin-left: 0;
      }
    }
    &--autowidth {
      width: auto;
    }
    &--toright {
      right: 0;
    }
    &--right {
      top: 0;
      left: 100%;
      margin: 0 0 0 10px;        
    }
    &--left {
      top: 0;
      right: 100%;
      margin: 0 10px 0 0;
    }
    &--above {
      top: unset;
      bottom: 100%;
      margin: 0 0 10px 0;        
    }
  }
  &--opened {
    .selected {
      background-color: $color-main;
      svg {
        transform: rotate(180deg);
      }
      &:after {
        background-color: $color-main;
      }
    }

  }
}
