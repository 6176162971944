.footer {
  position: relative;
  z-index: 1;
  &__top {
    background-color: rgba($color-main, 0.7);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 110px;
    margin-top: -110px;
    overflow: hidden;
    column-gap: 30px;
    row-gap: 30px;
    @include mq($until: md) {
      padding-top: 40px;
      padding-bottom: 40px;
      flex-direction: column;
      background-color: $color-main;
      margin-top: 0;
      height: unset;
      padding-right: $padding-lg;
    }
    @include mq($until: sm) {
      padding-right: $padding-sm;
    }
    @include mq($until: xs) {
      padding-right: $padding-xs;
    }
    &-text {
      display: flex;
      column-gap: 80px;
      align-items: center;
      row-gap: 30px;
      @include mq($until: md) {
        flex-direction: column;
      }
    }
    p {
      color: #fff;
      font-size: rem(21px);
      font-weight: 700;
      white-space: nowrap;
      @include mq($until: 1400px) {
        font-size: rem(18px);
      }
      @include mq($until: xs) {
        white-space: normal;
        text-align: center;
      }
    }
    a {
      padding-top: 10px;
      padding-bottom: 10px;
      position: relative;
      z-index: 1;
      white-space: nowrap;
      @include mq($until: md) {
        padding-right: 0;
      }
      span {
        color: #fff;
        font-size: rem(21px);
        font-weight: 700;
        transition: ease 0.3s all;
        @include mq($until: 1400px) {
          font-size: rem(18px);
        }
        @include mq($until: xs) {
          display: none;
        }
        &:last-of-type {
          display: none;
          @include mq($until: xs) {
            display: inline;
          }  
        }
      }
      &:hover span {
        margin-right: 20px;
        @include mq($until: md) {
          margin-right: 0;
        }
      }
      &:after {
        content: '';
        position: absolute;
        z-index: -1;
        left: -30px;
        top: 0;
        width: 200%;
        height: 100%;
        background: #091940;
        transform: skew(-30deg, 0deg);
        transition: ease 0.3s all;
        @include mq($until: md) {
          width: calc(100% + 60px);
        }
      }    
    }
  }
  &__content {
    background-color: #091940;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
    row-gap: 40px;
    z-index: 1;
    position: relative;
    @include mq($until: 1320px) {
      flex-direction: column;
    }
    &-left {
      display: flex;
      column-gap: 40px;
      row-gap: 40px;
      white-space: nowrap;
      @include mq($until: 1320px) {
        div:first-of-type {
          width: 240px;
        }
      }
     @include mq($until: 840px) {
        flex-direction: column;
        white-space: normal;
        div:first-of-type {
          width: 100%;
        }
      }
    }
    &-right {
      display: flex;
      column-gap: 40px;
      row-gap: 40px;
      @include mq($until: 840px) {
        flex-direction: column;
      }
      div:first-of-type {
        white-space: nowrap;
      }
      div:last-of-type {
        padding-top: 35px;
        white-space: nowrap;
        @include mq($until: 840px) {
          padding-top: 0;
        }
      }
    }
    &-phones {
      p {
        display: flex;
        column-gap: 5px;
      }
      .flags {
        flex-grow: 1;
        text-align: right;
        padding-left: 5px;
        @include mq($until: 840px) {
          flex-grow: 0;
        }
        img:first-of-type {
          margin-right: 8px;
        }
      }
    }
    a {
      color: #fff;
      &:hover {
        color: $color-main;
      }
    }
    p {
      color: #fff;
      font-size: 14px;
      margin-bottom: 15px;
      strong {
        font-weight: 700;
      }
      &:last-of-type {
        margin-bottom: 0;
      }    
    }
  }
  &__contact-link {
    position: fixed;
    bottom: 0;
    z-index: 6;
    background-color: $color-main;
    color: #fff;
    width: 100%;
    text-align: center;
    padding: 20px;
    visibility: hidden;
    @include mq($until: md) {
      visibility:visible;
    }
    &:hover {
      color: #fff;
    }
  }
  &-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    @include mq($until: sm) {
      flex-direction: column;
      row-gap: 10px;
    }
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img{
        margin-left: 15px;
      }
    }
    .rodo, &__copyright, span{
      color: #091940;
      opacity: 0.7;
      transition: ease 0.3s opacity;
      font-size: 13px;
      &:hover {
        opacity: 1;
      }
    }
  }
}
