.expert {
    background-position: center;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-top: 100px;
    padding-bottom: 190px;
    overflow: hidden;
    @include mq($until: lg) {
        background-position: right top;
        background-repeat: no-repeat;
        background-size: 150%;
        padding-top: 60vh;
        background-color: #011038;
        padding-bottom: 0;
    }
    @include mq($until: xs) {
        min-height: unset;
        padding-top: 300px;
    }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 65.55vw;
        height: 200%;
        background: linear-gradient(90deg, #14295A 0%, #0A1B42 50%, #011038 100%);
        opacity: 0.93;
        mix-blend-mode: multiply;
        @include mq($until: lg) {
            display: none;
        }
    }
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-image: url('/themes/default/assets/images/sekcja-tir.png');
        @include mq($until: lg) {
            background-image: url('/themes/default/assets/images/sekcja-tir2.jpg');
            background-size: 150%;
            background-repeat: no-repeat;
            background-position: top right;
            background-color: #011038;        
        }
    }
    &__content {
        position: relative;
        z-index: 1;
        @include mq($until: lg) {
            padding-bottom: 190px;
            /*&:after {
                content: '';
                position: absolute;
                left: -50px;
                top: 0;
                width: 100vw;
                height: 100%;
                background: linear-gradient(180deg, transparent 0%,  #011038 55% ,  #011038 100%);
                z-index: -1;
            }*/
        }
        @include mq($until: lg) {
            text-align: center;
        }
        h2 {
            font-size: rem(55px);
            margin-top: 0;
            margin-bottom: 0;
            color: #fff;
            @include mq($until: xl) {
                font-size: 4vw;
            }
            @include mq($until: xs) {
                font-size: 7vw;
            }
        }
        ul {
            margin-top: 80px;
            @include mq($until: lg) {
                text-align: left;
                display: inline-block;
            }
        }
        li {
            color: #fff;
            padding-left: 100px;
            font-style: italic;
            font-weight: 700;
            font-size: rem(31px);
            @include mq($until: xl) {
                font-size: 2.2vw;
            }
            @include mq($until: xs) {
                font-size: 14px;
            }
            &:before {
                width: 60px;
                top: 50%;
                transform: skew(-30deg, 0deg) translateY(-50%);
            }
            &:nth-child(1) {
                margin-left: 100px;
                @include mq($until: lg) {
                    margin-left: 0;
                }
            }
            &:nth-child(2) {
                margin-left: 50px;
                @include mq($until: lg) {
                    margin-left: 0;
                }
            }
        }
    }
    &__link {
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 100px;
        @include mq($until: lg) {
            bottom: 80px;
        }
        @include mq($until: lg) {
            bottom: 80px;
        }
        @include mq($until: sm) {
            bottom: 40px;
        }
        a {
            background-color: $color-main;
            padding-top: 18px;
            padding-bottom: 18px;
            padding-right: 40px;
            transition: ease 0.3s all;
            position: relative;
            display: block;
            @include mq($until: xs) {
                padding-right: 20px;
            }
            &:hover span {
                padding-left: 20px;
            }
            &:hover, &:hover:after {
                background-color: $color-extra;
            }
            span {
                position: relative;
                z-index: 1;
                font-style: italic;
                font-weight: 700;
                font-size: rem(35px);
                color: #fff;
                display: block;
                transition: ease 0.3s all;
                @include mq($until: sm) {
                    font-size: rem(30px);
                }
                @include mq($until: xs) {
                    font-size: rem(25px);
                }
            }
            &:after {
                content: '';
                position: absolute;
                right: -30px;
                top: 0;
                width: 200%;
                height: 100%;
                background: $color-main;
                transform: skew(-30deg, 0deg);
                transition: ease 0.3s all;
            }
        }
    }
}