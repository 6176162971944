.hero {
    height: 100vh;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    background-color: #091940;

    @include mq($until: md) {
        height: unset;
    }
    &__content {
        padding-top: 100px;
        padding-bottom: 190px;
        position: relative;
        z-index: 1;
        transition: ease 0.3s all;
        @include mq($until: md) {
            text-align: center;
        }
        h1 {
            color: #fff;
            font-size: rem(60px);
            margin-top: 0;
            margin-bottom: 0;
            @include mq($until: md) {
                font-size: rem(50px);
            }
            @include mq($until: xs) {
                font-size: 7vw;
            }
        }
        p {
            color: #fff;
        }
        a {
            display: inline-block;
            font-style: italic;
            font-weight: 700;
            font-size: rem(45px);
            text-decoration: underline;
            transition: ease 0.3s all;
            margin-top: 40px;
            cursor: pointer;
            @include mq($until: md) {
                font-size: rem(40px);
            }
            @include mq($until: xs) {
                font-size: 6vw;
            }
            img {
                margin-left: 30px;
                @include mq($until: xs) {
                    width: 14vw;
                }
            }
            &:hover {
                color: $color-extra;
            }
        }
    }
    &__link {
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 100px;
        @include mq($until: lg) {
            bottom: 80px;
        }
        @include mq($until: lg) {
            bottom: 80px;
        }
        @include mq($until: sm) {
            bottom: 40px;
        }
        a {
            background-color: $color-extra;
            padding-top: 18px;
            padding-bottom: 18px;
            padding-right: 40px;
            transition: ease 0.3s all;
            position: relative;
            display: block;
            @include mq($until: xs) {
                padding-right: 20px;
            }
            &:hover span {
                padding-left: 20px;
            }
            &:hover, &:hover:after {
                background-color: $color-main;
            }
            span {
                position: relative;
                z-index: 1;
                font-style: italic;
                font-weight: 700;
                font-size: rem(35px);
                color: #fff;
                display: block;
                transition: ease 0.3s all;
                @include mq($until: sm) {
                    font-size: rem(30px);
                }
                @include mq($until: xs) {
                    font-size: rem(25px);
                }
            }
            &:after {
                content: '';
                position: absolute;
                right: -30px;
                top: 0;
                width: 200%;
                height: 100%;
                background: $color-extra;
                transform: skew(-30deg, 0deg);
                transition: ease 0.3s all;
            }
        }
    }
    &__image {
        background-position: center;
        background-size: cover;    
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        @include mq($until: md) {
            position: relative;
            aspect-ratio: 16/9;
        }
    }
    video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;      
        opacity: 0;
        transition: ease 0.3s all;
        transform: translateY(-50%) translateX(-50%);
        @include mq($until: md) {
            width: 100%;
            min-height: unset;     
            top: 0;
            left: 0;
            transform: none; 
        }
        &.visible {
            opacity: 1;
        }
    }
    @include mq($until: md) {
        .lang-switcher {
            display: none;
        }
    }
}
