.about {
    background-position: center top;
    background-size: cover;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &__main {
        position: relative;
        grid-template-columns: 1fr 33vw;
        display: grid;
        justify-content: space-between;
        align-items: center;
        @include mq($until: lg) {
            grid-template-columns: 1fr;
        }
    }
    &__content {
        max-width: 1140px;
        width: 100%;
        padding-top: 100px;
        padding-bottom: 100px;
        padding-right: 300px;
        @include mq($until: lg) {
            max-width: 100%;
            padding-right: $padding-lg;
            text-align: center;
            margin: 0 auto;
        }
        @include mq($until: sm) {
            padding-right: $padding-sm;
        }
        @include mq($until: xs) {
            padding-right: $padding-xs;
        }
        h2 {
            font-size: rem(37px);
            margin-bottom: 40px;
            @include mq($until: xl) {
                font-size: rem(27px);
            }
        }
        p {
            font-style: italic;
            font-weight: 500;
            font-size: rem(21px);
            margin-top: 30px;
            margin-bottom: 0;
        }
    }
    &__gallery {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 25px;
        row-gap: 15px;
        margin-left: -200px;
        padding-top: 100px;
        padding-bottom: 100px;
        @include mq($until: lg) {
            margin-left: 0;
            padding-left: $padding-lg;
            grid-template-columns: repeat(3, 1fr);
            padding-top: 0;
        }
        @include mq($until: sm) {
            padding-left: $padding-sm;
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq($until: xs) {
            padding-left: $padding-xs;
        }
        @include mq($until: 400px) {
            grid-template-columns: 1fr;
        }
        &-single {
            position: relative;
            z-index: 1;
            width: 100%;
            &:before {
                background-color: $color-extra;
                opacity: 0.8;
                z-index: 1!important;
            }
            img {
                width: 100%;
            }
        }
        &:before {
            content: '';
            position: absolute;
            left: 65.55vw;
            top: 0;
            width: 34.45vw;
            height: 300vh;
            background: linear-gradient(90deg, #14295A 0%, #0A1B42 50%, #011038 100%);
            opacity: 0.93;
            mix-blend-mode: multiply;
            @include mq($until: lg) {
                display: none;
            }
        }
    }
    &__foto {
        position: relative;
        z-index: 1;
        img {
            width: 100%;
        }
    }
    &__image {
        padding-top: 100px;
        padding-bottom: 190px;
        position: relative;
        img {
            z-index: 1;
            position: relative;
            margin-left: -140px;
        }
    }
    &__link {
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 120px;
        @include mq($until: lg) {
            bottom: 80px;
        }
        @include mq($until: lg) {
            bottom: 80px;
        }
        @include mq($until: sm) {
            bottom: 40px;
        }
        a {
            background-color: $color-main;
            padding-top: 18px;
            padding-bottom: 18px;
            padding-right: 40px;
            transition: ease 0.3s all;
            position: relative;
            display: block;
            @include mq($until: xs) {
                padding-right: 20px;
            }
            &:hover span {
                padding-right: 20px;
            }
            &:hover, &:hover:after {
                background-color: $color-extra;
            }
            span {
                position: relative;
                z-index: 1;
                font-style: italic;
                font-weight: 700;
                font-size: rem(35px);
                color: #fff;
                display: block;
                transition: ease 0.3s all;
                @include mq($until: sm) {
                    font-size: rem(30px);
                }
                @include mq($until: xs) {
                    font-size: rem(25px);
                }
            }
            &:after {
                content: '';
                position: absolute;
                left: -30px;
                top: 0;
                width: 200%;
                height: 100%;
                background: $color-main;
                transform: skew(-30deg, 0deg);
                transition: ease 0.3s all;
            }
        }
    }

}
