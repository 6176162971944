.offer {
    background-position: center;
    background-size: cover;
    position: relative;
    grid-template-columns: 1fr 33vw;
    display: grid;
    overflow: hidden;
    min-height: 110vh;
    justify-content: space-between;
    align-items: stretch;
    @include mq($until: lg) {
        grid-template-columns: 1fr;
    }
    &:before {
        content: '';
        position: absolute;
        left: 65.55vw;
        top: 0;
        width: 34.45vw;
        height: 200%;
        background: linear-gradient(90deg, #14295A 0%, #0A1B42 50%, #011038 100%);
        opacity: 0.93;
        mix-blend-mode: multiply;
        @include mq($until: lg) {
            display: none;
        }
    }
    &__content {
        max-width: 1140px;
        width: 100%;
        padding-top: 100px;
        padding-bottom: 240px;
        padding-right: 300px;
        align-self: center;
        @include mq($until: 17px) {
            max-width: 1040px;
            padding-right: 200px;
        }
        @include mq($until: lg) {
            padding-bottom: 240px;
            padding-right: $padding-lg;
            text-align: center;
        }
        @include mq($until: sm) {
            padding-right: $padding-sm;
        }
        @include mq($until: xs) {
            padding-right: $padding-xs;
        }
        h4 {
            font-size: rem(37px);
            @include mq($until: xl) {
                font-size: rem(27px);
            }
        }
        span {
            font-style: italic;
            font-weight: 500;
            font-size: rem(21px);
            margin-top: 30px;
        }
        .icons-set {
            column-gap: 40px;
            row-gap: 80px;
            margin: 0;
            &__item {
                display: grid;
                grid-template-columns: 87px 1fr;
                column-gap: 40px;
                align-items: start;
                @include mq($until: xl) {
                    grid-template-columns: 1fr;
                    .image {
                        position: absolute;
                    }
                    img {
                        width: 80px;
                    }
                    h4 {
                        padding-left: 100px;
                        height: 80px;
                        display: flex;
                        align-items: center;                
                    }    
                }
                @include mq($until: lg) {
                    .image {
                        position: relative;
                    }
                    h4 {
                        padding-left: 0;
                        height: unset;
                        display: block;
                    }
                }
                &:hover img {
                    transform: rotate(360deg);
                }
                img {
                    transition: ease 1.3s all;
                }
            }
        }
    }
    &__image {
        padding-top: 100px;
        padding-bottom: 100px;
        position: relative;
        @include mq($until: lg) {
            padding-left: $padding-lg;
            order: -1;
            padding-bottom: 0;
        }
        @include mq($until: sm) {
            padding-left: $padding-sm;
        }
        @include mq($until: xs) {
            padding-left: $padding-xs;
        }
        img {
            z-index: 1;
            position: relative;
            margin-left: -180px;
            max-width: 600px;
            @include mq($until: 152px) {
                margin-left: -40px;
            }
            @include mq($until: xl) {
                max-width: 500px;
            }
            @include mq($until: lg) {
                margin-left: auto;
                margin-right: auto;
                display: block;
                width: 100%;
            }
        }
    }

    &__link {
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 100px;
        @include mq($until: lg) {
            bottom: 80px;
        }
        @include mq($until: lg) {
            bottom: 80px;
        }
        @include mq($until: sm) {
            bottom: 40px;
        }
        a {
            background-color: $color-main;
            padding-top: 18px;
            padding-bottom: 18px;
            padding-right: 40px;
            transition: ease 0.3s all;
            position: relative;
            display: block;
            @include mq($until: xs) {
                padding-right: 20px;
            }
            &:hover span {
                padding-left: 20px;
            }
            &:hover, &:hover:after {
                background-color: $color-extra;
            }
            span {
                position: relative;
                z-index: 1;
                font-style: italic;
                font-weight: 700;
                font-size: rem(35px);
                color: #fff;
                display: block;
                transition: ease 0.3s all;
                @include mq($until: sm) {
                    font-size: rem(30px);
                }
                @include mq($until: xs) {
                    font-size: rem(25px);
                }
            }
            &:after {
                content: '';
                position: absolute;
                right: -30px;
                top: 0;
                width: 200%;
                height: 100%;
                background: $color-main;
                transform: skew(-30deg, 0deg);
                transition: ease 0.3s all;
            }
        }
    }

}
