.experience {
    background-position: center;
    background-size: cover;
    position: relative;
    grid-template-columns: 1fr 33vw;
    display: grid;
    overflow: hidden;
    min-height: 110vh;
    justify-content: space-between;
    @include mq($until: lg) {
        grid-template-columns: 1fr;
    }
    &:before {
        content: '';
        position: absolute;
        left: 65.55vw;
        top: 0;
        width: 34.45vw;
        height: 100%;
        background: linear-gradient(90deg, #14295A 0%, #0A1B42 50%, #011038 100%);
        opacity: 0.93;
        mix-blend-mode: multiply;
        @include mq($until: lg) {
            display: none;
        }
    }
    &__content {
        width: 100%;
        max-width: 1000px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        padding-top: 100px;
        padding-bottom: 100px;
        padding-right: 300px;
        @include mq($until: 1860px) {
            padding-right: 200px;
        }
        @include mq($until: lg) {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            padding-right: $padding-lg;
            max-width: 100%;
            padding-bottom: 240px;
        }
        @include mq($until: md) {
            padding-right: $padding-sm;
            padding-bottom: 100px;

        }
        @include mq($until: xs) {
            padding-right: $padding-xs;
        }
        h2 {
            font-size: rem(55px);
            margin-top: 0;
            margin-bottom: 0;
            @include mq($until: md) {
                font-size: rem(50px);
            }
            @include mq($until: xs) {
                font-size: rem(27px);
            }
        }
        h3 {
            background-color: $color-main;
            padding: 10px 0;
            color: #fff;
            font-size: rem(30px);
            margin-top: 20px;
            margin-bottom: 80px;
            display: inline-block;
            margin-left: 140px;
            position: relative;
            z-index: 1;
            text-align: center;
            @include mq($until: 1440px) {
                margin-left: 40px;
            }
            @include mq($until: lg) {
                margin-left: 0;
            }
            @include mq($until: xs) {
                max-width: 140px;
                font-size: rem(20px);
            }
            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                right: -30px;
                top: 0;
                width: 100%;
                height: 100%;
                background: $color-main;
                transform: skew(-20deg, 0deg);
                transition: ease 0.3s all;
            }
            &:before {
                content: '';
                position: absolute;
                z-index: -1;
                left: -30px;
                top: 0;
                width: 100%;
                height: 100%;
                background: $color-main;
                transform: skew(-20deg, 0deg);
                transition: ease 0.3s all;
            }
        }
        p {
            font-size: rem(21px);
            font-style: italic;
            font-weight: 500;
            text-shadow: 0px 0px 24px #fff;
            @include mq($until: 1420px) {
                font-size: rem(20px);
            }
            @include mq($until: 1360px) {
                font-size: rem(19px);
            }
    
        }
    }
    &__features {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        padding-top: 100px;
        padding-bottom: 100px;
        margin-left: -80px;
        @include mq($until: lg) {
            margin-left: 0;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 100vw;
                height: 100%;
                background: linear-gradient(90deg, #14295A 0%, #0A1B42 50%, #011038 100%);
                opacity: 0.93;
                mix-blend-mode: multiply;
            }
        }
        h4 {
            color: #fff;
            font-size: rem(30px);
            width: 245px;
            @include mq($until: xs) {
                max-width: 245px;
                font-size: rem(20px);
            }

        }
        .icons-set {
            row-gap: 40px;
            column-gap: 30px;
            z-index: 1;
            @include mq($until: lg) {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
            @include mq($until: md) {
                grid-template-columns: 1fr;
            }
            &__item {
                column-gap: 50px;
                transition: ease 0.3s all;
                @include mq($until: md) {
                    text-align: center;
                    flex-direction: column;
                }
                &:hover {
                    margin-left: -20px;
                }
            }
        }        
        img {
            width: 155px;
            @include mq($until: xs) {
                max-width: 155px;
            }

        }
    }
}
