.gallery {
    display: grid;
    grid-gap: 30px;

    .gallery__link {
        transition: transform .3s;
        display: inline-block;
        overflow: hidden;
        background-size: cover;
        background-position: center center;
    }

    &__img {
        width: 100%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        transition: box-shadow .3s;
    }
    .caption {
        background: #fff;
        padding: 10px 20px;
        text-align: center;
        h4 {
            margin-top: 0;
            margin-bottom: 0;
        }
        p {
            margin-top: 10px;
        }
    }

    &.template1 {
        grid-template-columns: repeat(2, 1fr);
        @include mq($until: lg) {
            grid-gap: 20px;
        }
        @include mq($until: sm) {
            grid-gap: 10px;
        }
       @include mq($until: 400px) {
            grid-template-columns: 1fr;
        }
    }
    &.template2 {
        grid-template-columns: repeat(3, 1fr);
        @include mq($until: lg) {
            grid-gap: 20px;
        }
        @include mq($until: sm) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
        }
        @include mq($until: 400px) {
            grid-template-columns: 1fr;
        }
    }
    &.template3 {
        grid-template-columns: repeat(4, 1fr);
        @include mq($until: lg) {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
        }
        @include mq($until: sm) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
        }
        @include mq($until: 400px) {
            grid-template-columns: 1fr;
        }
    }
    &.template4 {
        grid-template-columns: repeat(5, 1fr);
        @include mq($until: xl) {
            grid-template-columns: repeat(4, 1fr);
        }
        @include mq($until: lg) {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
        }
        @include mq($until: sm) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
        }
        @include mq($until: 400px) {
            grid-template-columns: 1fr;
        }
    }

    /*
    &.template5 {
        //633 336
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(2, 1fr);
        .gallery__link:nth-of-type(6n+1) { grid-area: 1 / 1 / 2 / 7; }
        .gallery__link:nth-of-type(6n+2) { grid-area: 1 / 7 / 2 / 10; }
        .gallery__link:nth-of-type(6n+3) { grid-area: 1 / 10 / 2 / 13; }
        .gallery__link:nth-of-type(6n+4) { grid-area: 2 / 1 / 3 / 4; }
        .gallery__link:nth-of-type(6n+5) { grid-area: 2 / 4 / 3 / 7; }
        .gallery__link:nth-of-type(6n+6) { grid-area: 2 / 7 / 3 / 13; }
    }
    &.template6 {
        //336 633
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(2, 1fr);
        .gallery__link:nth-of-type(6n+1) { grid-area: 1 / 1 / 2 / 4; }
        .gallery__link:nth-of-type(6n+2) { grid-area: 1 / 4 / 2 / 7; }
        .gallery__link:nth-of-type(6n+3) { grid-area: 1 / 7 / 2 / 13; }
        .gallery__link:nth-of-type(6n+4) { grid-area: 2 / 1 / 3 / 7; }
        .gallery__link:nth-of-type(6n+5) { grid-area: 2 / 7 / 3 / 10; }
        .gallery__link:nth-of-type(6n+6) { grid-area: 2 / 10 / 3 / 13; }
    }
    &.template7 {
        //84 48
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(2, 1fr);
        .gallery__link:nth-of-type(4n+1) { grid-area: 1 / 1 / 2 / 9; }
        .gallery__link:nth-of-type(4n+2) { grid-area: 1 / 9 / 2 / 13; }
        .gallery__link:nth-of-type(4n+3) { grid-area: 2 / 1 / 3 / 5; }
        .gallery__link:nth-of-type(4n+4) { grid-area: 2 / 5 / 3 / 13; }
    }
    &.template8 {
        //48 84
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(2, 1fr);
        .gallery__link:nth-of-type(4n+1) { grid-area: 1 / 1 / 2 / 5; }
        .gallery__link:nth-of-type(4n+2) { grid-area: 1 / 5 / 2 / 13; }
        .gallery__link:nth-of-type(4n+3) { grid-area: 2 / 1 / 3 / 9; }
        .gallery__link:nth-of-type(4n+4) { grid-area: 2 / 9 / 3 / 13; }
    }
    &.template9 {
        //48 84
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(3, 1fr);
        .gallery__link:nth-of-type(6n+1) { grid-area: 1 / 1 / 2 / 9; }
        .gallery__link:nth-of-type(6n+2) { grid-area: 1 / 9 / 2 / 13; }
        .gallery__link:nth-of-type(6n+3) { grid-area: 2 / 1 / 4 / 5; }
        .gallery__link:nth-of-type(6n+4) { grid-area: 2 / 5 / 3 / 13; }
        .gallery__link:nth-of-type(6n+5) { grid-area: 3 / 5 / 4 / 9; }
        .gallery__link:nth-of-type(6n+6) { grid-area: 3 / 9 / 4 / 13; }
    }
    */
}
