$buttonLineHeight: 5px;
[data-hover] {
    display: inline-block;
    position: relative;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    overflow: hidden;
    transition: ease 0.3s color, ease 0.3s background, ease 0.3s opacity, ease 0.3s transform, ease 0.3s border-color;
}
[data-hover*='overlay'] {
    &:after, &:before {
        z-index: 0!important;
        opacity: 0.5;
    }
}

// Transform Effects
[data-hover*='grow']:hover {
    transform: scale(1.1);
}
[data-hover*='shrink']:hover {
    transform: scale(0.9);
}
[data-hover*='float-up']:hover {
    transform: translateY(-10px);
}
[data-hover*='float-down']:hover {
    transform: translateY(10px);
}
[data-hover*='float-left']:hover {
    transform: translateX(-10px);
}
[data-hover*='float-right']:hover {
    transform: translateX(10px);
}
[data-hover*='rotate5']:hover {
    transform: rotate(5deg);
}
[data-hover*='rotate-5']:hover {
    transform: rotate(-5deg);
}

// Background Effects
[data-hover*='fade']:hover {
    background-color: $color-main;
    color: #fff;
}
[data-hover*='overlay fade']:hover {
    background-color: unset;
    color: unset;
}
[data-hover*='overlay fade']:after {
    content: '';
    background-color: $color-main;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: ease 0.3s all;
}
[data-hover*='overlay fade']:hover:after {
    opacity: 0.5;
}
[data-hover*='move-left']:after {
    content: '';
    background-color: $color-main;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    transition: ease 0.3s all;
    z-index: -1;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
}
[data-hover*='move-left']:hover {
    color: #fff;
}
[data-hover*='move-left']:hover:after {
    width: 100%;
}
[data-hover*='overlay'][data-hover*='move-left'][data-hover*='move-right']:hover:after {
    width: 50%;
}
[data-hover*='move-right']:before {
    content: '';
    background-color: $color-main;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 0;
    transition: ease 0.3s all;
    z-index: -1;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
}
[data-hover*='move-right']:hover {
    color: #fff;
}
[data-hover*='move-right']:hover:before {
    width: 100%;
}
[data-hover*='overlay'][data-hover*='move-right'][data-hover*='move-left']:hover:before {
    width: 50%;
}
[data-hover*='move-top']:after {
    content: '';
    background-color: $color-main;
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 100%;
    transition: ease 0.3s all;
    z-index: -1;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
}
[data-hover*='move-top']:hover {
    color: #fff;
}
[data-hover*='move-top']:hover:after {
    height: 100%;
}
[data-hover*='overlay'][data-hover*='move-top'][data-hover*='move-bottom']:hover:after {
    height: 50%;
}
[data-hover*='move-bottom']:before {
    content: '';
    background-color: $color-main;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    transition: ease 0.3s all;
    z-index: -1;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
}
[data-hover*='move-bottom']:hover {
    color: #fff;
}
[data-hover*='move-bottom']:hover:before {
    height: 100%;
}
[data-hover*='overlay'][data-hover*='move-bottom'][data-hover*='move-top']:hover:before {
    height: 50%;
}
[data-hover*='expand-h']:before {
    content: '';
    background-color: $color-main;
    position: absolute;
    right: 50%;
    top: 0;
    height: 100%;
    width: 0;
    transition: ease 0.3s all;
    z-index: -1;
    -webkit-transform: perspective(1px) translateZ(0) translateX(50%);
    transform: perspective(1px) translateZ(0) translateX(50%);
}
[data-hover*='expand-h']:hover {
    color: #fff;
}
[data-hover*='expand-h']:hover:before {
    width: 100%;
}
[data-hover*='expand-v']:before {
    content: '';
    background-color: $color-main;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0;
    transition: ease 0.3s all;
    z-index: -1;
    -webkit-transform: perspective(1px) translateZ(0) translateY(-50%);
    transform: perspective(1px) translateZ(0) translateY(-50%);
}
[data-hover*='expand-v']:hover {
    color: #fff;
}
[data-hover*='expand-v']:hover:before {
    height: 100%;
}
[data-hover*='expand-center']:before {
    content: '';
    background-color: $color-main;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transition: ease 0.3s all;
    z-index: -1;
    -webkit-transform: perspective(1px) translateZ(0) scale(0);
    transform: perspective(1px) translateZ(0) scale(0);
}
[data-hover*='expand-center']:hover {
    color: #fff;
}
[data-hover*='expand-center']:hover:before {
    -webkit-transform: perspective(1px) translateZ(0) scale(1);
    transform: perspective(1px) translateZ(0) scale(1);
}
[data-hover*='blob-']:before {
    content: '';
    background-color: $color-main;
    position: absolute;
    height: 10px;
    width: 10px;
    transition: ease 0.5s all;
    border-radius: 100%;
    z-index: -1;
    -webkit-transform: perspective(1px) translateZ(0) scale(0);
    transform: perspective(1px) translateZ(0) scale(0);
}
[data-hover*='blob-top-left']:before {
    left: 0;
    top: 0;
    border-top-left-radius: 0;
}
[data-hover*='blob-top-right']:before {
    right: 0;
    top: 0;
    border-top-right-radius: 0;
}
[data-hover*='blob-bottom-left']:before {
    left: 0;
    bottom: 0;
    border-bottom-left-radius: 0;
}
[data-hover*='blob-bottom-right']:before {
    right: 0;
    bottom: 0;
    border-bottom-right-radius: 0;
}
[data-hover*='blob-']:hover {
    color: #fff;
}
[data-hover*='blob-']:hover:before {
    -webkit-transform: perspective(1px) translateZ(0) scale(100);
    transform: perspective(1px) translateZ(0) scale(100);
}

// Line Effects
[data-hover*='underline'] {
    overflow: hidden;
    float: left;
}
[data-hover*='underline']:after {
    content: '';
    height: $buttonLineHeight;
    position: absolute;
    bottom: 0;
    background-color: $color-main;
    width: 0;
    transition: ease 0.3s all;
}
[data-hover*='underline-left']:after {
    left: 0;
}
[data-hover*='underline-right']:after {
    right: 0;
}
[data-hover*='underline-center']:after {
    left: 50%;
    transform: translateX(-50%);
}
[data-hover*='underline']:hover:after {
    width: 100%;
}
[data-hover*='underline-float']:after {
    left: 0;
    height: 0;
    width: 100%;
}
[data-hover*='underline-float']:hover:after {
    left: 0;
    height: $buttonLineHeight;
}

[data-hover*='overline'] {
    overflow: hidden;
    float: left;
}
[data-hover*='overline']:before {
    content: '';
    height: $buttonLineHeight;
    position: absolute;
    top: 0;
    background-color: $color-main;
    width: 0;
    transition: ease 0.3s all;
}
[data-hover*='overline-left']:before {
    left: 0;
}
[data-hover*='overline-right']:before {
    right: 0;
}
[data-hover*='overline-center']:before {
    left: 50%;
    transform: translateX(-50%);
}
[data-hover*='overline']:hover:before {
    width: 100%;
}
[data-hover*='overline-float']:before {
    left: 0;
    height: 0;
    width: 100%;
}
[data-hover*='overline-float']:hover:before {
    left: 0;
    height: $buttonLineHeight;
}

[data-hover*='leftline'] {
    overflow: hidden;
    float: left;
}
[data-hover*='leftline']:before {
    content: '';
    width: $buttonLineHeight;
    position: absolute;
    left: 0;
    background-color: $color-main;
    height: 0;
    transition: ease 0.3s all;
}
[data-hover*='leftline-top']:before {
    top: 0;
}
[data-hover*='leftline-bottom']:before {
    bottom: 0;
}
[data-hover*='leftline-center']:before {
    top: 50%;
    transform: translateY(-50%);
}
[data-hover*='leftline']:hover:before {
    height: 100%;
}
[data-hover*='leftline-float']:before {
    top: 0;
    height: 100%;
    width: 0;
}
[data-hover*='leftline-float']:hover:before {
    width: $buttonLineHeight;
}

[data-hover*='rightline'] {
    overflow: hidden;
    float: left;
}
[data-hover*='rightline']:after {
    content: '';
    width: $buttonLineHeight;
    position: absolute;
    right: 0;
    background-color: $color-main;
    height: 0;
    transition: ease 0.3s all;
}
[data-hover*='rightline-top']:after {
    top: 0;
}
[data-hover*='rightline-bottom']:after {
    bottom: 0;
}
[data-hover*='rightline-center']:after {
    top: 50%;
    transform: translateY(-50%);
}
[data-hover*='rightline']:hover:after {
    height: 100%;
}
[data-hover*='rightline-float']:after {
    top: 0;
    height: 100%;
    width: 0;
}
[data-hover*='rightline-float']:hover:after {
    width: $buttonLineHeight;
}


[data-hover*='icon-show-left'] i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -20px;
    transition: ease 0.3s all;
}
[data-hover*='icon-show-left']:hover i {
    left: 20px;
}
[data-hover*='icon-show-right'] i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -20px;
    transition: ease 0.3s all;
}
[data-hover*='icon-show-right']:hover i {
    right: 20px;
}
[data-hover*='icon-push-left']{
    transition: ease 0.3s all;
    & i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -20px;
        transition: ease 0.3s all;
    }
    &:hover {
        padding-left: 40px;
        & i {
            left: 20px;
        }
    }
}
[data-hover*='icon-push-right']{
    transition: ease 0.3s all;
    & i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -20px;
        transition: ease 0.3s right;
    }
    &:hover {
        padding-right: 40px;
        & i {
            right: 20px;
        }
    }
}
[data-hover*='icon-space-left'] i {
    transition: ease 0.3s all;
}
[data-hover*='icon-space-left']:hover i {
    padding-right: 20px;
}
[data-hover*='icon-space-right'] i {
    transition: ease 0.3s all;
}
[data-hover*='icon-space-right']:hover i {
    padding-left: 20px;
}
[data-animation*="rotate"]:hover [data-animation*="this"] {
    animation: 1s linear infinite rotate;
}

@keyframes rotate {
    from {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(360deg)
    }
}
