.subsite {
    .hero {
        height: 600px;
        position: relative;
        background-color: #091940;
        overflow: unset;

        @include mq($until: md) {
            aspect-ratio: 16/9;
            height: unset;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 200px;
            background: linear-gradient(180deg, #091940 0%, transparent 100%);
        }
        h1 {
            color: #fff;
            position: absolute;
            z-index: 1;
            left: 0;
            bottom: 100px;
            padding-top: 18px;
            padding-bottom: 18px;
            padding-right: 40px;
            font-size: rem(35px);
            @include mq($until: lg) {
                bottom: 80px;
            }
            @include mq($until: lg) {
                bottom: 80px;
            }
            @include mq($until: md) {
                bottom: -60px;
            }
            @include mq($until: sm) {
                font-size: rem(25px);
            }
            @include mq($until: xs) {
                padding-right: 20px;
                font-size: 4vw;
            }
            &:after {
                content: '';
                position: absolute;
                right: -30px;
                top: 0;
                width: 200%;
                height: 100%;
                background: #D21113;
                transform: skew(-30deg, 0deg);
                transition: ease 0.3s all;
                z-index: -1;
            }
        }
    }
    .main-content {
        padding-top: 100px;
        padding-bottom: 100px;
        overflow: hidden;
        .gallery-referencje a {
            background-position: top center;
        }
        @include mq($until: xs) {
            .contact {
                p span {
                    display: block;
                }
            }
        }
        p, span, li {
            font-style: italic;
            font-size: rem(21px);
            font-weight: 500;
        }
    }
    .footer__top {
        margin-top: 0;
        background-color: $color-main;
      }
}
