.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 150px;
  column-gap: 30px;
   //border-bottom: solid 2px $color-main;

  @include mq($until: 1880px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 30px;
  }
  @include mq($until: md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #091940;
  }

  &--absolute {
    position: absolute;
    z-index: 3;
    width: 100%;
    border-bottom: none;
    @include mq($until: md) {
      position: relative;
      z-index: 99999;
    }
    .header__menuLink {
      color: #fff;
    }
  }

  &--fixed {
    transition: background-color .3s ease-in-out;
    &.sticky {
      position: fixed;
      z-index: 3;
      width: 100%;
      background-color: lemonchiffon;
      .header__menuLink {
        color: #3e3e3e;
      }
    }
  }

  &__logo{
    position: relative;
    z-index: 2;
  }

  &__nav {
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    column-gap: 10px;
    row-gap: 10px;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    padding: 0;
    margin: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #3e3e3e;
    font-weight: 700;
    font-size: rem(19px);
    padding: 8px 13px;
    border-radius: 5px;
    margin: 0;
    border: 1px solid transparent;
    transition: ease 0.3s border;
    display: block;
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      border-color: $color-main;
    }
    @include mq($until: 1320px) {
      font-size: 18px;
    }
    @include mq($until: 1270px) {
      font-size: 17px;
    }
    @include mq($until: 1220px) {
      font-size: 16px;
    }
    @include mq($until: 1080px) {
      letter-spacing: -0.4px;
      font-size: 15px;
    }
  }
  .lang-switcher {
    display: none;
    @include mq($until: md) {
      position: relative;
      top: 0;
      display: block;
      ul {
        margin: 0;
      }
      li {
        font-weight: 700;
        font-size: 15px;
        color: #fff;
        padding: 8px 13px;
        &:before {
          display: none;
        }
      }
      img {
        margin-left: 10px;
      }
    }

  }
}
